import PropTypes from 'prop-types';
import { createContext, useEffect, useReducer } from 'react';

// third-party
import { Chance } from 'chance';
import jwtDecode from 'jwt-decode';

// reducer - state management
import { LOGIN, LOGOUT, VERIFY_MFA } from 'store/actions';
import accountReducer from 'store/accountReducer';
import axios from 'axios';

// project imports
import Loader from 'ui-component/Loader';

const chance = new Chance();

// constant
const initialState = {
    isLoggedIn: false,
    isInitialized: false,
    mfaVerified: false,
    user: null
};

const verifyToken = (accessToken) => {
    if (!accessToken) {
        return false;
    }
    const decoded = jwtDecode(accessToken);

    /**
     * Property 'exp' does not exist on type '<T = unknown>(token, options?: JwtDecodeOptions | undefined) => T'.
     */
    return decoded.exp > Date.now() / 1000;
};

const setSession = (accessToken) => {
    if (accessToken) {
        localStorage.setItem('accessToken', accessToken);
       // axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
    } else {
        window.localStorage.removeItem('accessToken');
       // delete axios.defaults.headers.common.Authorization;
    }
};

// ==============================|| JWT CONTEXT & PROVIDER ||============================== //
const JWTContext = createContext(null);

export const JWTProvider = ({ children }) => {
    const [state, dispatch] = useReducer(accountReducer, initialState);

    useEffect(() => {
        const init = async () => {
            try {
                const accessToken = window.localStorage.getItem('accessToken');
                if (accessToken && verifyToken(accessToken)) {

                    setSession(accessToken);
                    const decodedToken = jwtDecode(accessToken);

                    const { agent } = decodedToken;

                    if (agent.mfaVerified && agent.mfaVerified === true) {
                        dispatch({
                            type: LOGIN,
                            payload: {
                                isLoggedIn: true,
                                mfaVerified: true,
                                user: agent
                            }
                        });
                    }
                    else {
                        dispatch({
                            type: LOGIN,
                            payload: {
                                isLoggedIn: true,
                                user: agent
                            }
                        });
                    }
                } else {
                    dispatch({
                        type: LOGOUT
                    });
                }
            } catch (err) {
                console.error(err);
                dispatch({
                    type: LOGOUT
                });
            }
        };

        init();
    }, [dispatch]);

    const login = async (email, password) => {

        const header =  {headers: {'Content-Type': 'application/json;charset=UTF-8',"Access-Control-Allow-Origin": "*"}}; 
        const axiosInstance = axios.create();
        axiosInstance.interceptors.response.use(response => response, error => Promise.reject((error.response && error.response.data) || "Unknown error"));
       try{
       const response = await axiosInstance.post(process.env.REACT_APP_API_BASE + "/login", {email,password,client:'WEB'},header); //@ts-ignore

        const { accessToken, user } = response.data;
        setSession(accessToken);
        dispatch({
            type: LOGIN,
            payload: {
                isLoggedIn: true,
                user
            }
        });
    }
    catch(err) {
     
     throw new Error(err.message ? err.message : "No Response from Backserver")
    }

    };

    const verifyMFA = (data) => {
        const { accessToken, user } = data;

        setSession(accessToken);

        dispatch({
            type: VERIFY_MFA,
            payload: {
                mfaVerified: true,
                user
            }
        })
    }

    const register = async (email, password, firstName, lastName) => {
        // todo: this flow need to be recode as it not verified
        const id = chance.bb_pin();
        const response = axios.post('/api/account/register', {
            id,
            email,
            password,
            firstName,
            lastName
        });
        let users = response.data;

        if (window.localStorage.getItem('users') !== undefined && window.localStorage.getItem('users') !== null) {
            const localUsers = window.localStorage.getItem('users');
            users = [
                ...JSON.parse(localUsers),
                {
                    id,
                    email,
                    password,
                    name: `${firstName} ${lastName}`
                }
            ];
        }

        window.localStorage.setItem('users', JSON.stringify(users));
    };

    const logout = () => {
        setSession(null);
        dispatch({ type: LOGOUT });
    };

    const resetPassword = (email) => console.log(email);

    const updateProfile = () => {};

    if (state.isInitialized !== undefined && !state.isInitialized) {
        return <Loader />;
    }

    return (
        <JWTContext.Provider value={{ ...state, login, verifyMFA, logout, register, resetPassword, updateProfile }}>{children}</JWTContext.Provider>
    );
};

JWTProvider.propTypes = {
    children: PropTypes.node
};

export default JWTContext;