import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

// project imports
import useAuth from 'hooks/useAuth';

// ==============================|| AUTH GUARD ||============================== //

/**
 * Authentication guard for routes
 * @param {PropTypes.node} children children element/node
 */
const AuthGuard = ({ children }) => {
    const { isLoggedIn, mfaVerified, user } = useAuth();
    const navigate = useNavigate();

    useEffect(() => {
        if (!isLoggedIn) {
            navigate('login', { replace: true });
        }
        else {//I had to place it here too or else people can skip the MFA by going to the URL for inbox
            if (user?.mfa_enabled && !mfaVerified) {
                navigate('mfaverify', {replace: true})
            }
        }
    }, [isLoggedIn, navigate, mfaVerified, user?.mfa_enabled]);

    return children;
};

AuthGuard.propTypes = {
    children: PropTypes.node
};

export default AuthGuard;
