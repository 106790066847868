import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

// project imports
import useAuth from 'hooks/useAuth';
import { DASHBOARD_PATH } from 'config';

// ==============================|| GUEST GUARD ||============================== //

/**
 * Guest guard for routes having no auth required
 * @param {PropTypes.node} children children element/node
 */

const GuestGuard = ({ children }) => {
    const { isLoggedIn, mfaVerified, user } = useAuth();
    const navigate = useNavigate();
    
    useEffect(() => {
        if (isLoggedIn && window.localStorage.getItem('accessToken')) {
            if ((user?.mfa_enabled && mfaVerified) || !user?.mfa_enabled) {
                navigate(DASHBOARD_PATH, { replace: true });
            }
            else if (user?.mfa_enabled && !mfaVerified) {
                navigate('mfaverify', {replace: true})
            }
        }
        else {
            navigate('login', { replace: true });
        }
    }, [isLoggedIn, navigate, mfaVerified, user?.mfa_enabled]);

    return children;
};

GuestGuard.propTypes = {
    children: PropTypes.node
};

export default GuestGuard;
