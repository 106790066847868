// action - state management
import { LOGIN, LOGOUT, REGISTER, VERIFY_MFA } from './actions';

// ==============================|| ACCOUNT REDUCER ||============================== //

const initialState = {
    isLoggedIn: false,
    mfaVerified: false,
    isInitialized: false,
    user: null
};

const accountReducer = (state = initialState, action) => {
    switch (action.type) {
        case REGISTER: {
            const { user } = action.payload;
            return {
                ...state,
                user
            };
        }
        case LOGIN: {
            const { user, mfaVerified } = action.payload;

            if (mfaVerified && mfaVerified === true) {
                return {
                    ...state,
                    isLoggedIn: true,
                    isInitialized: true,
                    mfaVerified: true,
                    user
                };
            }
            else {
                return {
                    ...state,
                    isLoggedIn: true,
                    isInitialized: true,
                    user
                };
            }
        }
        case VERIFY_MFA: {
            const { mfaVerified } = action.payload;
            return {
                ...state,
                mfaVerified: mfaVerified
            }
        }
        case LOGOUT: {
            //{MFA}
            //Set MFA_Auth'd to false
            return {
                ...state,
                isInitialized: true,
                isLoggedIn: false,
                mfaVerified: false,
                user: null
            };
        }
        default: {
            return { ...state };
        }
    }
};

export default accountReducer;
